import _ from "lodash";

interface TabObject {
  title: string;
  url: string;
  style?: any;
}

export function tabSettingValidation(tabs: TabObject[]) {
  if (_.isEmpty(tabs)) {
    return true;
  }
  const validatedTabs: boolean[] = _.map(tabs, (tab: TabObject) => {
    return !!(tab.title && tab.url);
  });
  if (validatedTabs.includes(false)) {
    return false;
  } else {
    return true;
  }
}

export function mapChanges(obj: any) {
  _.forEach(obj, (val, key) => {
    if (Array.isArray(val)) {
      obj[key] = _.last(val);
    } else if (typeof val === "object") {
      mapChanges(obj[key]);
    } else {
      delete obj[key];
    }
  });
}
