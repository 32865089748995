<template>
  <div>
    <!-- DEBUGGER -->
    <el-div style="margin-bottom: 20px">
      <v-jsoneditor
        v-model="results"
        :options="jsonOptions"
        height="800px"
        style="margin-top: 20px"
        :loading="loading"
      ></v-jsoneditor>
    </el-div>
  </div>
</template>

<script>
import _ from "lodash";
import VJsoneditor from "v-jsoneditor";
import gql from "graphql-tag";
// import { graph } from "@/store/api";

export default {
  components: { VJsoneditor },
  data() {
    return {
      results: {},
      loading: false,

      // json viewer
      jsonOptions: {
        mode: "tree",
        indentation: 2,
        limitDragging: true,
        modes: ["form", "tree", "preview", "code"],
      },
    };
  },
  apollo: {
    getDebugData: {
      query: gql`
        query {
          Bot {
            protectedKeys
          }
        }
      `,
      fetchPolicy: "network-only",
      update(result) {
        this.results = _.get(result, "Bot.protectedKeys", {});
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      },
    },
  },
};
</script>
