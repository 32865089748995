<template>
  <div>
    <el-card v-if="prechatform.enabled">
      <div slot="header">
        <h2 class="keyreply-page-title">Pre Chat Form</h2>
      </div>
      <div>
        <el-row>
          <el-form
            :rules="prechatFormRules"
            :model="prechatform"
            ref="prechatform"
            label-width="180px"
            class="prechatform"
          >
            <el-form-item label="Pre-Chat Greeting" prop="greeting">
              <el-input
                type="textarea"
                v-model="prechatform.greeting"
                placeholder="Please enter a greeting message to the visitor"
              ></el-input>
            </el-form-item>
            <el-form-item label="Require Topic" prop="promptTopic">
              <el-checkbox v-model="prechatform.promptTopic.enabled">
                Visitors must choose a topic
              </el-checkbox>
            </el-form-item>
            <el-form-item label="Require Name" prop="promptName">
              <el-checkbox v-model="prechatform.promptName">
                Visitors must provide their name
              </el-checkbox>
            </el-form-item>
            <el-form-item label="Require Email" prop="promptEmail">
              <el-checkbox v-model="prechatform.promptEmail">
                Visitors must provide their email
              </el-checkbox>
            </el-form-item>
            <el-form-item label="Require Number" prop="promptNumber">
              <el-checkbox v-model="prechatform.promptNumber">
                Visitors must provide their phone number
              </el-checkbox>
            </el-form-item>
            <el-form-item label="Terms and Conditions" prop="termsAndConditions">
              <el-checkbox v-model="prechatform.termsAndConditions.enabled">
                Visitors must accept the Terms and Conditions
              </el-checkbox>
              <el-input
                type="textarea"
                v-model="prechatform.termsAndConditions.url"
                placeholder="Please enter a URL/link for the terms and conditions"
                :disabled="!prechatform.termsAndConditions.enabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="Privacy Policy" prop="privacyPolicy">
              <el-checkbox v-model="prechatform.privacyPolicy.enabled">
                Visitors must accept the Privacy Policy
              </el-checkbox>
              <el-input
                type="textarea"
                v-model="prechatform.privacyPolicy.url"
                placeholder="Please enter a URL/link for the privacy policy"
                :disabled="!prechatform.privacyPolicy.enabled"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="prechatResetForm()">Reset</el-button>
            </el-form-item>
          </el-form>
        </el-row>
      </div>
      <div />
    </el-card>
    <el-card v-if="postchatform.enabled">
      <div slot="header">
        <h2 class="keyreply-page-title">Post Chat Form</h2>
      </div>
      <div>
        <el-row>
          <el-form
            :model="postchatform"
            ref="postchatform"
            label-width="180px"
            class="postchatform"
          >
            <el-form-item label="Post-Chat Farewell" prop="farewell">
              <el-checkbox v-model="postchatform.farewell.enabled">
                Show farewell message to visitors
              </el-checkbox>
              <el-input
                type="textarea"
                v-model="postchatform.farewell.message"
                placeholder="Please enter a farewell message to visitors"
                :disabled="!postchatform.farewell.enabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="Rating" prop="rating">
              <el-checkbox v-model="postchatform.rating.enabled">
                Show star rating to visitors
              </el-checkbox>
              <el-checkbox
                v-model="postchatform.rating.optional"
                :disabled="!postchatform.rating.enabled"
              >
                Is optional
              </el-checkbox>
              <el-input
                type="textarea"
                v-model="postchatform.rating.question"
                placeholder="Please enter a rating question or message shown before rating"
                :disabled="!postchatform.rating.enabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="Feedback" prop="feedback">
              <el-checkbox v-model="postchatform.feedback.enabled">
                Show feedback question to visitors
              </el-checkbox>
              <el-checkbox
                v-model="postchatform.feedback.optional"
                :disabled="!postchatform.feedback.enabled"
              >
                Is optional
              </el-checkbox>
              <el-input
                type="textarea"
                v-model="postchatform.feedback.question"
                placeholder="Please enter a feedback question"
                :disabled="!postchatform.feedback.enabled"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="postchatResetForm()">Reset</el-button>
            </el-form-item>
          </el-form>
        </el-row>
      </div>
      <div />
    </el-card>
    <el-button type="primary" @click="saveChanges()">Save</el-button>
  </div>
</template>
<script>
import _ from "lodash";
import gql from "graphql-tag";
import * as jsondiffpatch from "jsondiffpatch/dist/jsondiffpatch.umd";
import { mapChanges } from "@/helperMethods/modules";

export default {
  data() {
    return {
      originalModules: {},
      prechatform: {
        enabled: false,
        greeting: "",
        promptName: false,
        promptEmail: false,
        promptNumber: false,
        termsAndConditions: {
          enabled: false,
          url: "",
        },
        privacyPolicy: {
          enabled: false,
          url: "",
        },
        promptTopic: {
          enabled: false,
          topics: [],
        },
      },
      postchatform: {
        enabled: false,
        farewell: {
          enabled: false,
          message: "",
        },
        rating: {
          enabled: true,
          optional: true,
          question: "I hope you've found me helpful today! Could you please rate my service?",
        },
        feedback: {
          enabled: true,
          optional: true,
          question: "Can you provide me with some feedback so I can do better?",
        },
      },
      prechatFormRules: {
        greeting: [
          {
            required: true,
            message: "Prechat Greeting is required",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    modules: {
      get() {
        return this.$store.state.modules;
      },
      set(value) {
        this.$store.state.modules = value;
      },
    },
  },
  mounted() {
    this.saveOriginalModules();
    const modulePrechatform = _.get(this.modules, "prechatform");
    if (modulePrechatform) {
      Object.assign(this.prechatform, modulePrechatform);
    }
    const modulePostchatform = _.get(this.modules, "postchatform");
    if (modulePostchatform) {
      Object.assign(this.postchatform, modulePostchatform);
    }
    this.prechatform.enabled = _.get(this.modules, "webchat.showPreChatForm.enabled", false);
    this.postchatform.enabled = _.get(this.modules, "webchat.showPostChatForm.enabled", false);
  },
  methods: {
    mapChanges,
    saveOriginalModules() {
      this.originalModules = _.cloneDeep(this.modules);
    },
    prechatValidateForm() {
      let validForm = false;
      this.$refs["prechatform"].validate((valid) => {
        validForm = valid;
      });
      return validForm;
    },

    prechatResetForm() {
      this.$confirm("This will reset the form data. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        const emptyPreChatForm = {
          enabled: true,
          greeting: "",
          promptName: false,
          promptEmail: false,
          promptNumber: false,
          termsAndConditions: {
            enabled: false,
            url: "",
          },
          privacyPolicy: {
            enabled: false,
            url: "",
          },
          promptTopic: {
            enabled: false,
            topics: [],
          },
        };

        this.prechatform = emptyPreChatForm;
      });
    },

    postchatResetForm() {
      this.$confirm("This will reset the form data. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        const emptyPostChatForm = {
          enabled: true,
          farewell: {
            enabled: false,
            message: "",
          },
          rating: {
            enabled: false,
            optional: true,
            question: "I hope you've found me helpful today! Could you please rate my service?",
          },
          feedback: {
            enabled: false,
            optional: true,
            question: "Can you provide me with some feedback so I can do better?",
          },
        };

        this.postchatform = emptyPostChatForm;
      });
    },

    async saveChanges(event) {
      const validPrechatForm = this.prechatValidateForm();
      if (!validPrechatForm) return;

      const form = this.prechatform;
      if (form.promptTopic.enabled) {
        form.promptTopic.topics = this.topics;
      }

      if (this.prechatform.enabled) {
        Object.assign(this.modules, {
          prechatform: this.prechatform,
        });
      }
      if (this.postchatform.enabled) {
        Object.assign(this.modules, {
          postchatform: this.postchatform,
        });
      }

      const changes = jsondiffpatch.diff(this.originalModules, this.modules);
      this.mapChanges(changes);

      try {
        await this.$store.dispatch("SAVE_MODULES", {
          modules: changes,
        });
        this.$message({
          type: "success",
          message: "Config Saved",
        });
        this.saveOriginalModules();
      } catch (err) {
        this.$message({
          type: "error",
          message: "Error saving config",
        });
      }
    },
  },

  apollo: {
    topics() {
      return {
        query: gql`
          query {
            faqAPI {
              dataset: getDataset
            }
          }
        `,
        update: (data) => {
          const dataset = _.get(data, "faqAPI.dataset");
          _.forEach(dataset, (intent) => {
            const topics = _.get(intent, "topics", ["general"]);

            if (_.isEmpty(topics)) {
              topics.push("general");
            }

            _.assign(intent, {
              topics,
            });
          });

          return _.chain(dataset).map("topics").flatten().uniq().value();
        },
      };
    },
  },
};
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.field-description {
  margin-left: 10px;
  color: grey;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
